var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "mt-5" },
    [
      _c("v-card-title", [
        _vm._v(_vm._s(_vm.$t("console.platforms.mturk.hits.create.hit.title"))),
      ]),
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("v-simple-table", { staticClass: "mt-4", attrs: { dense: "" } }, [
            _c("tbody", [
              _c("tr", [
                _c(
                  "td",
                  { attrs: { width: "300px" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "console.platforms.mturk.hits.create.hit.numHITs"
                          )
                        ) +
                        " "
                    ),
                    _c("required-badge"),
                  ],
                  1
                ),
                _c(
                  "td",
                  [
                    _c("validated-text-field", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        filled: "",
                        min: 1,
                        step: 1,
                        type: "number",
                        rules: [_vm.rules.positiveInteger],
                      },
                      model: {
                        value: _vm.inputs.numCreateHITs,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "numCreateHITs", _vm._n($$v))
                        },
                        expression: "inputs.numCreateHITs",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c(
            "v-card-subtitle",
            [
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.$t("console.platforms.mturk.hits.create.hit.parameters")
                  )
                ),
              ]),
              _c("help-button", { attrs: { name: "CreateHITWithHITType" } }),
            ],
            1
          ),
          _c("v-simple-table", {
            attrs: { dense: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("tbody", [
                      _c("tr", [
                        _c(
                          "td",
                          { attrs: { width: "300" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "console.platforms.mturk.hits.create.hit.table.maxAsgmt.text"
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { right: "", "max-width": "300" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        !_vm.isMaxAssignmentsEditable
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: { small: "" },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.isMaxAssignmentsEditable = true
                                                      },
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v("mdi-pencil")]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { small: "", dark: "" } },
                                      [_vm._v("mdi-alert")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "console.platforms.mturk.hits.create.hit.table.maxAsgmt.tooltip"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("validated-text-field", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                disabled: !_vm.isMaxAssignmentsEditable,
                                type: "number",
                                min: "1",
                                step: "1",
                                rules: [_vm.rules.positiveInteger],
                              },
                              model: {
                                value: _vm.inputs.hitParams.MaxAssignments,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.inputs.hitParams,
                                    "MaxAssignments",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "inputs.hitParams.MaxAssignments",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "console.platforms.mturk.hits.create.hit.table.lifeTime"
                                )
                              )
                            ),
                            _c("required-badge"),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("validated-text-field", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                type: "number",
                                min: "0",
                                step: "10",
                                rules: [_vm.rules.positiveInteger],
                              },
                              model: {
                                value: _vm.inputs.hitParams.LifetimeInSeconds,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.inputs.hitParams,
                                    "LifetimeInSeconds",
                                    _vm._n($$v)
                                  )
                                },
                                expression:
                                  "inputs.hitParams.LifetimeInSeconds",
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.secondsToFormattedTime(
                                    _vm.inputs.hitParams.LifetimeInSeconds,
                                    ["weeks", "days", "hrs", "mins", "secs"]
                                  )
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "console.platforms.mturk.hits.create.hit.table.reqAnnot"
                                )
                              )
                            ),
                            _c("required-badge"),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c("validated-text-field", {
                              attrs: { rules: [() => true] },
                              model: {
                                value: _vm.inputs.hitParams.RequesterAnnotation,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.inputs.hitParams,
                                    "RequesterAnnotation",
                                    $$v
                                  )
                                },
                                expression:
                                  "inputs.hitParams.RequesterAnnotation",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }