<template>
    <v-card>
        <v-card-title>
            {{ $t('console.platforms.mturk.hits.create.hitType.title') }}
            <help-button name="QualificationRequirements"></help-button>
        </v-card-title>

        <v-card-text>
            <v-radio-group class="mt-0" fixed top hide-details v-model="inputs.createNew">
                <v-radio
                    :label="$t('console.platforms.mturk.hits.create.hitType.radio.new')"
                    :value="true"
                    @click="clearHITTypeParams()"
                ></v-radio>
                <v-radio
                    :label="$t('console.platforms.mturk.hits.create.hitType.radio.existing')"
                    :value="false"
                ></v-radio>
            </v-radio-group>
        </v-card-text>

        <v-form v-model="valid">
            <div v-if="inputs.createNew">

                <v-simple-table dense class="mt-4">
                    <tbody>
                        <tr>
                            <td width="300px">
                                {{ $t('console.platforms.mturk.hits.create.hitType.metaParameters.name.title') }}
                                <required-badge></required-badge>
                                <br>
                                <span class="text-caption grey--text">
                                    {{ $t('console.platforms.mturk.hits.create.hitType.metaParameters.name.subtitle') }}
                                </span>
                            </td>
                            <td>
                                <validated-text-field
                                    filled
                                    v-model="inputs.tuttiHITBatchName"
                                    :rules="[rules.required]"
                                    style="width:400px;"
                                />
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
                <v-simple-table dense class="mt-4">
                    <tbody>
                        <tr class="no-bottom-border">
                            <td width="300px">
                                {{ $t('console.platforms.mturk.hits.create.hitType.metaParameters.project') }}
                                <required-badge></required-badge>
                            </td>
                            <td>
                                <validated-autocomplete
                                    v-model="inputs.projectName"
                                    :items="projectNames"
                                    :rules="[rules.required]"
                                    style="width:400px;"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>
                                <rebuild-alert
                                    :type="alertType"
                                    :client="client"
                                    :project-name="inputs.projectName"
                                    @rebuild-finish="checkProjectDiff(inputs.projectName)"
                                ></rebuild-alert>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <v-card-subtitle>
                    <b>{{ $t('console.platforms.mturk.hits.create.hitType.params.title') }}</b>
                    <help-button name="CreateHITType"></help-button>
                </v-card-subtitle>

                <v-simple-table dense>
                    <template v-slot:default>
                        <tbody>
                            <tr v-for="option in HITTypeParamOptions" :key="option.name">
                                <td width="300px">
                                    {{ $t(option.i18npath) }} <required-badge v-if="option.name!=='Keywords'" />
                                </td>
                                <td>
                                    <div  v-if="['AutoApprovalDelayInSeconds', 'AssignmentDurationInSeconds'].indexOf(option.name) > -1">
                                        <validated-text-field
                                            v-model.number="inputs.hitTypeParams[option.name]"
                                            :rules="option.rules"
                                            v-bind="option.attrs"
                                        />
                                        {{ secondsToFormattedTime(inputs.hitTypeParams[option.name], ['weeks', 'days', 'hrs', 'mins', 'secs']) }}
                                    </div>
                                    <div  v-else>
                                        <validated-text-field
                                            v-model="inputs.hitTypeParams[option.name]"
                                            :rules="option.rules"
                                            v-bind="option.attrs"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(qualItem, qualIndex) in inputs.hitTypeParams.QualificationRequirements" :key="'QualificationRequirements-'+qualIndex">
                                <!--<td>QualificationRequirements - {{ qualIndex+1 }}</td>-->
                                <td>{{ String($t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.text')) }} - {{ qualIndex+1 }}</td>
                                <td>
                                    <v-simple-table dense>
                                        <tbody>
                                            <tr>
                                                <!--<td>QualificationTypeId <required-badge /></td>-->
                                                <td>{{ $t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.qualTypeId') }}<required-badge /></td>
                                                <td>
                                                    <validated-autocomplete
                                                        v-model="qualItem['QualificationTypeId']"
                                                        :items="allQualIds"
                                                        :rules="[rules.required]"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <!--<td>Comparator <required-badge /></td>-->
                                                <td>{{ $t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.comparator') }}<required-badge /></td>
                                                <td>
                                                    <validated-select
                                                        v-model="qualItem['Comparator']"
                                                        :items="qualRequirementOptions['Comparator']"
                                                        :rules="[rules.required]"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <!--<td>IntegerValues</td>-->
                                                <td>{{ $t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.intVals') }}</td>
                                                <td>
                                                    <validated-combobox
                                                        multiple
                                                        small-chips
                                                        append-icon
                                                        v-model="qualItem['IntegerValues']"
                                                        :rules="[rules.numbers]"
                                                    />
                                                </td>
                                            </tr>
                                            <tr v-for="(localeItem, localeIndex) in qualItem.LocaleValues" :key="'LocaleValues-'+localeIndex">
                                                <!--<td>LocaleValues - {{ localeIndex+1 }}</td>-->
                                                <td>{{ String($t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.localeVals.text')) }} - {{ localeIndex+1 }}</td>
                                                <td>
                                                    <v-simple-table dense>
                                                        <template v-slot:default>
                                                            <tbody>
                                                                <tr>
                                                                    <!--<td>Country <required-badge /></td>-->
                                                                    <td>{{ $t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.localeVals.table.country') }}<required-badge /></td>
                                                                    <td>
                                                                        <validated-text-field
                                                                            v-model="localeItem.Country"
                                                                            :rules="[rules.required]"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <!--<td>Subdivision <required-badge /></td>-->
                                                                    <td>{{ $t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.localeVals.table.subdivision') }}<required-badge /></td>
                                                                    <td>
                                                                        <validated-text-field
                                                                            v-model="localeItem.Subdivision"
                                                                            :rules="[rules.required]"
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </template>
                                                    </v-simple-table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <!--<b>{{ getNumLocaleValues(qualItem) }} LocaleValues</b>-->
                                                    <b>{{ getNumLocaleValues(qualItem) }} {{ $t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.localeVals.text') }}</b>
                                                    <v-icon small @click="pushLocaleValues(qualItem)">mdi-plus</v-icon>
                                                    <v-icon small @click="popLocaleValues(qualItem)">mdi-minus</v-icon>
                                                </td>
                                            </tr>
                                            <tr>
                                                <!--<td>ActionsGuarded <required-badge /></td>-->
                                                <td>{{ $t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.table.actionsGuarded') }}<required-badge /></td>
                                                <td>
                                                    <validated-select
                                                        v-model="qualItem['ActionsGuarded']"
                                                        :items="qualRequirementOptions['ActionsGuarded']"
                                                        :rules="[rules.required]"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </v-simple-table>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <b>
                                        {{ inputs.hitTypeParams.QualificationRequirements ? inputs.hitTypeParams.QualificationRequirements.length : 0 }} {{ String($t('console.platforms.mturk.hits.create.hitType.params.optionNames.qualReq.text')) }}
                                    </b>
                                    <help-button x-small name="QualificationRequirements" />
                                    <v-icon small @click="pushQualRequirements()">mdi-plus</v-icon>
                                    <v-icon small v-if="inputs.hitTypeParams.QualificationRequirements.length" @click="popQualRequirements()">mdi-minus</v-icon>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>

            <div v-else-if="!inputs.createNew">
                <v-card-subtitle>
                    <b>{{ $t('console.platforms.mturk.hits.create.hitType.existingHits.title') }}</b> <required-badge />
                    <v-icon v-if="rules.required(inputs.selectedHITBatch)===true" right color="success">mdi-check</v-icon>
                </v-card-subtitle>
                <v-simple-table dense>
                    <template v-slot:default>
                        <thead><tr style="background-color:#eee;">
                            <th></th>
                            <th>{{ $t('console.platforms.mturk.hits.create.hitType.existingHits.headers.name') }}</th>
                            <th>{{ $t('console.platforms.mturk.hits.create.hitType.existingHits.headers.title') }}</th>
                            <th>{{ $t('console.platforms.mturk.hits.create.hitType.existingHits.headers.hitTypeId') }}</th>
                            <th>{{ $t('console.platforms.mturk.hits.create.hitType.existingHits.headers.action') }}</th>
                        </tr></thead>

                        <tbody>
                            <tr v-if="hitBatches.length===0">
                                <td colspan="5">{{ $t('console.platforms.mturk.hits.create.hitType.existingHits.text') }}</td>
                            </tr>
                            <tr v-for="hitBatch in hitBatches" :key="'HITType-'+hitBatch.hit_type.id">
                                <td>
                                    <v-radio-group class="ma-0 pa-0" dense hide-details v-model="inputs.selectedHITBatch" :rules="[rules.required]">
                                        <v-radio :value="hitBatch" />
                                    </v-radio-group>
                                </td>
                                <td>{{ hitBatch.name }}</td>
                                <td>{{ hitBatch.hit_type.Title }}</td>
                                <td>{{ hitBatch.hit_type.id }}</td>
                                <td>
                                    <v-btn small text color="indigo" @click="copyToNew(hitBatch.hit_type)">Copy to new</v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-divider></v-divider>

                <div v-if="inputs.selectedHITBatch">
                    <v-card-subtitle><b>{{ $t('console.platforms.mturk.hits.create.hitType.selectedHitBatch.title') }}</b> <help-button name="CreateHITType" /></v-card-subtitle>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td width="300px">{{ $t('console.platforms.mturk.hits.create.hitType.selectedHitBatch.table.project') }}</td>
                                    <td>{{ inputs.selectedHITBatch.project_name }}</td>
                                </tr>
                                <tr v-for="option in HITTypeParamOptions" :key="option.name">
                                    <td>{{ $t(option.i18npath) }}</td>
                                    <td>
                                        {{ inputs.selectedHITBatch.hit_type[option.name] }}
                                        <span v-if="['AutoApprovalDelayInSeconds', 'AssignmentDurationInSeconds'].indexOf(option.name) > -1">
                                            ({{ secondsToFormattedTime(inputs.selectedHITBatch.hit_type[option.name], ['weeks', 'days', 'hrs', 'mins', 'secs']) }})
                                        </span>
                                    </td>
                                </tr>
                                <tr v-for="(qualItem, qualIndex) in dropTuttiHITBatchQualTypes(inputs.selectedHITBatch.hit_type.QualificationRequirements)" :key="'QualificationRequirements-'+qualIndex">
                                    <td>{{ String($t('console.platforms.mturk.hits.create.hitType.selectedHitBatch.table.qualReq')) }} - {{ qualIndex+1 }}</td>
                                    <td>
                                        <v-simple-table dense>
                                            <tbody>
                                                <tr v-for="key in ['QualificationTypeId', 'Comparator', 'IntegerValues']" :key="`inputs.selectedHITBatch-qual-${qualIndex}-${key}`">
                                                    <td>{{ key }}</td><td>{{ qualItem[key] }}</td>
                                                </tr>
                                                <tr v-for="(localeItem, localeIndex) in qualItem.LocaleValues" :key="'LocaleValues-'+localeIndex">
                                                    <td>{{ String($t('console.platforms.mturk.hits.create.hitType.selectedHitBatch.table.localeVals.text')) }} - {{ localeIndex+1 }}</td>
                                                    <td>
                                                        <v-simple-table dense>
                                                            <template v-slot:default>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{{ $t('console.platforms.mturk.hits.create.hitType.selectedHitBatch.table.localeVals.table.country') }}</td>
                                                                        <td>{{ localeItem.Country }}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{{ $t('console.platforms.mturk.hits.create.hitType.selectedHitBatch.table.localeVals.table.subdivision') }}</td>
                                                                        <td>{{ localeItem.Subdivision }}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </v-simple-table>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{{ $t('console.platforms.mturk.hits.create.hitType.selectedHitBatch.table.actionsGuarded') }}</td><td>{{ qualItem["ActionsGuarded"] }}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </div>
        </v-form>

        <tutti-snackbar ref="snackbar" />
    </v-card>
</template>

<script>
import HelpButton from '../../ui/HelpButton'
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'
import ValidatedTextField from './ui/ValidatedTextField'
import ValidatedSelect from './ui/ValidatedSelect'
import ValidatedCombobox from './ui/ValidatedCombobox'
import ValidatedAutocomplete from './ui/ValidatedAutocomplete'
import RequiredBadge from './ui/RequiredBadge'
import RebuildAlert from './RebuildAlert'
import rules from '@/lib/input-rules'
import { secondsToFormattedTime } from '@/lib/utils'
import {
    HITTypeParamOptions,
    defaultHITTypeParams,
    qualRequirementOptions,
    defaultQualRequirements,
    systemAssignedQualIds
} from './create-hit-manifest'

export default {
    components: {
        HelpButton,
        TuttiSnackbar,
        ValidatedTextField,
        ValidatedSelect,
        ValidatedCombobox,
        ValidatedAutocomplete,
        RequiredBadge,
        RebuildAlert
    },
    data: () => ({
        rules,
        qualRequirementOptions,
        HITTypeParamOptions,

        valid: false,

        hitBatches: [],
        customQualTypes: [],
        projectNames: [],
        alertType: null,

        inputs: {
            createNew: true,
            projectName: null,
            selectedHITBatch: null,
            tuttiHITBatchName: '',
            hitTypeParams: defaultHITTypeParams,
        },
    }),
    props: ['client'],
    computed: {
        allQualIds() {
            var ret = [];
            for(const i in systemAssignedQualIds){
                const id = systemAssignedQualIds[i].id;
                const name = systemAssignedQualIds[i].name;
                ret.push({ text: `${name} - ${id}`, value: id });
            }
            for(const qualType of this.customQualTypes){
                ret.push({ text: `${qualType.Name} - ${qualType.QualificationTypeId}`, value: qualType.QualificationTypeId });
            }
            return ret;
        },
    },
    methods: {
        secondsToFormattedTime,
        pushQualRequirements() {
            this.inputs.hitTypeParams.QualificationRequirements.push( Object.assign({}, defaultQualRequirements) );
        },
        popQualRequirements() {
            this.inputs.hitTypeParams.QualificationRequirements.pop();
        },
        getNumLocaleValues(item)  {
            return (item && item.LocaleValues) ? item.LocaleValues.length : 0;
        },
        pushLocaleValues(item) {
            if( !("LocaleValues" in item) ) this.$set(item, "LocaleValues", []);
            item.LocaleValues.push({ "Country": "", "Subdivision": "" });
        },
        popLocaleValues(item)  {
            item.LocaleValues.pop();
            if(item.LocaleValues.length==0) delete item.LocaleValues;
        },
        copyToNew(hitType){
            this.inputs.createNew = true;
            let _hitType = JSON.parse(JSON.stringify(hitType));
            _hitType.QualificationRequirements = this.dropTuttiHITBatchQualTypes(_hitType.QualificationRequirements);
            this.clearHITTypeParams(_hitType);
        },
        emitInputUpdate() {
            let _params = JSON.parse(JSON.stringify(this.inputs.hitTypeParams));
            if(_params){
                _params.AutoApprovalDelayInSeconds = parseInt(_params.AutoApprovalDelayInSeconds);
                _params.AssignmentDurationInSeconds = parseInt(_params.AssignmentDurationInSeconds);
            }
            this.$emit("update", this.inputs);
        },
        clearHITTypeParams(params) {
            this.inputs.selectedHITBatch = null;
            this.inputs.hitTypeParams = params || defaultHITTypeParams;
        },
        dropTuttiHITBatchQualTypes(qts) {
            const customQualTypeIds = this.customQualTypes.map((qt) => (qt.QualificationTypeId));
            return qts.filter((qt) => (customQualTypeIds.includes(qt.QualificationTypeId)));
        },
        async checkProjectDiff(projectName) {
            if(projectName) {
                try {
                    const projectHasDiff = await this.client.resource.checkProjectDiff({ project_name: projectName });
                    this.alertType = projectHasDiff ? 'warning' : 'success';
                } catch (e) {
                    if(e.code===this.client.ERROR.SYSTEM_BUILD.CONNECTION_FAILURE){
                        this.alertType = 'error';
                    }
                    console.error('unknown error in host api:', e);
                    this.alertType = 'error';
                }
            } else {
                this.alertType = null;
            }
        }
    },
    watch: {
        inputs: {
            deep: true,
            handler() { this.emitInputUpdate(); }
        },
        'inputs.projectName'(projectName) {
            this.checkProjectDiff(projectName);
        },
        valid() {
            this.$emit('validate', this.valid);
        }
    },
    mounted() {
        this.emitInputUpdate();
        this.client.invokeOnOpen(async () => {
            this.projectNames = (await this.client.resource.listProjects()).map(p => p.name);
            this.hitBatches = await this.client.mturk.listTuttiHITBatches();
            this.customQualTypes = await this.client.mturk.listQualificationTypes();
        });
    }
}
</script>
<style>
.no-bottom-border > td {
    border-bottom: 0!important;
    padding-bottom: 10px!important;
}
</style>
