var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-select",
    _vm._b(
      {
        staticClass: "tutti-validated-form",
        attrs: { dense: "", "hide-details": "" },
        on: {
          "update:error": function ($event) {
            return _vm.$emit("update:error", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function () {
              return [
                _c("v-icon", [_vm._v("mdi-menu-down")]),
                _vm.$attrs.rules.every((r) => r(_vm.value) === true)
                  ? _c("v-icon", { attrs: { color: "success" } }, [
                      _vm._v("mdi-check"),
                    ])
                  : _c("v-icon", { attrs: { color: "error" } }, [
                      _vm._v("mdi-exclamation"),
                    ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }