<template>
    <v-select
        dense
        hide-details
        v-model="inputValue"
        v-bind="$attrs"
        class="tutti-validated-form"
        @update:error="$emit('update:error', $event)"
    >
        <template v-slot:append>
            <v-icon>mdi-menu-down</v-icon>
            <v-icon v-if="$attrs.rules.every((r) => (r(value)===true))" color="success">mdi-check</v-icon>
            <v-icon v-else color="error">mdi-exclamation</v-icon>
        </template>
    </v-select>
</template>
<script>
export default {
    inheritAttrs: false,
    props: ['value'],
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        }
    }
}
</script>
<style>
.tutti-validated-form .v-input__slot {
    padding: 0 5px;
}
</style>