<template>
    <div>
        <v-alert
            v-if="type==='success'"
            dense
            outlined
            text
            type="success"
            icon="mdi-check-circle"
            class="text-caption"
        >
            <p class="mb-1">
                {{ $t('console.platforms.mturk.hits.create.hitType.rebuildAlert.notNeeded.title') }}
            </p>
            <p class="mb-1">
                <b>{{ $t('console.platforms.mturk.hits.create.hitType.rebuildAlert.notNeeded.actions.title') }}:</b>
                <ul>
                    <li>
                        <span v-html="$t('console.platforms.mturk.hits.create.hitType.rebuildAlert.notNeeded.actions.text', { url })"></span>
                    </li>
                </ul>
            </p>
        </v-alert>
        <v-alert
            v-else-if="type==='warning'"
            dense
            outlined
            text
            type="warning"
            icon="mdi-alert"
            class="text-caption"
        >
            <p class="mb-1">
                {{ $t('console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.title') }}
            </p>
            <p class="mb-1">
                <b>{{ $t('console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.actions.title') }}:</b>
                <ul>
                    <li>
                        <span v-html="$t('console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.actions.text[0]')"></span>
                        <br>
                        <v-btn
                            small
                            outlined
                            color="warning"
                            :loading="rebuilding"
                            @click="rebuildProject"
                        >
                            <v-icon small left>mdi-wrench</v-icon>
                            {{ $t('console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.rebuildButton') }}
                        </v-btn><br>
                    </li>
                    <li>
                        <span v-html="$t('console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.actions.text[1]', { url })"></span>
                    </li>
                </ul>
            </p>
        </v-alert>

        <v-alert
            v-else-if="type==='error'"
            dense
            outlined
            text
            type="error"
            icon="mdi-alert-decagram"
            class="text-caption"
        >
            <p class="mb-1" v-html="$t('console.platforms.mturk.hits.create.hitType.rebuildAlert.error.title')">
            </p>
            <p class="mb-1">
                <b>{{ $t('console.platforms.mturk.hits.create.hitType.rebuildAlert.error.actions.title') }}:</b>
                <ol>
                    <li>
                        <span v-html="$t('console.platforms.mturk.hits.create.hitType.rebuildAlert.error.actions.text[0]')"></span>
                    </li>
                    <li>
                        <span v-html="$t('console.platforms.mturk.hits.create.hitType.rebuildAlert.error.actions.text[1]')"></span>
                    </li>
                </ol>
            </p>
        </v-alert>
    </div>
</template>
<script>
import { getUrl } from "@/lib/tutti-env.js"

export default {
    data: () => ({
        rebuilding: false,
    }),
    props: {
        type: { type: String, default: null },
        projectName: { type: String, default: null },
        client: { default: null },
    },
    computed: {
        url() { return this.projectName ? getUrl(this.projectName) : null; },
    },
    methods: {
        async rebuildProject() {
            this.rebuilding = true;
            await this.client.resource.rebuildProject({ project_name: this.projectName });
            this.rebuilding = false;
            this.$emit('rebuild-finish');
        }
    }
}
</script>
