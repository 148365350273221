var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "success"
        ? _c(
            "v-alert",
            {
              staticClass: "text-caption",
              attrs: {
                dense: "",
                outlined: "",
                text: "",
                type: "success",
                icon: "mdi-check-circle",
              },
            },
            [
              _c("p", { staticClass: "mb-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.hits.create.hitType.rebuildAlert.notNeeded.title"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.hits.create.hitType.rebuildAlert.notNeeded.actions.title"
                      )
                    ) + ":"
                  ),
                ]),
                _c("ul", [
                  _c("li", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "console.platforms.mturk.hits.create.hitType.rebuildAlert.notNeeded.actions.text",
                            { url: _vm.url }
                          )
                        ),
                      },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm.type === "warning"
        ? _c(
            "v-alert",
            {
              staticClass: "text-caption",
              attrs: {
                dense: "",
                outlined: "",
                text: "",
                type: "warning",
                icon: "mdi-alert",
              },
            },
            [
              _c("p", { staticClass: "mb-1" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.title"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.actions.title"
                      )
                    ) + ":"
                  ),
                ]),
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.actions.text[0]"
                            )
                          ),
                        },
                      }),
                      _c("br"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            small: "",
                            outlined: "",
                            color: "warning",
                            loading: _vm.rebuilding,
                          },
                          on: { click: _vm.rebuildProject },
                        },
                        [
                          _c("v-icon", { attrs: { small: "", left: "" } }, [
                            _vm._v("mdi-wrench"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.rebuildButton"
                                )
                              ) +
                              " "
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                    ],
                    1
                  ),
                  _c("li", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "console.platforms.mturk.hits.create.hitType.rebuildAlert.needed.actions.text[1]",
                            { url: _vm.url }
                          )
                        ),
                      },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm.type === "error"
        ? _c(
            "v-alert",
            {
              staticClass: "text-caption",
              attrs: {
                dense: "",
                outlined: "",
                text: "",
                type: "error",
                icon: "mdi-alert-decagram",
              },
            },
            [
              _c("p", {
                staticClass: "mb-1",
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(
                      "console.platforms.mturk.hits.create.hitType.rebuildAlert.error.title"
                    )
                  ),
                },
              }),
              _c("p", { staticClass: "mb-1" }, [
                _c("b", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "console.platforms.mturk.hits.create.hitType.rebuildAlert.error.actions.title"
                      )
                    ) + ":"
                  ),
                ]),
                _c("ol", [
                  _c("li", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "console.platforms.mturk.hits.create.hitType.rebuildAlert.error.actions.text[0]"
                          )
                        ),
                      },
                    }),
                  ]),
                  _c("li", [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t(
                            "console.platforms.mturk.hits.create.hitType.rebuildAlert.error.actions.text[1]"
                          )
                        ),
                      },
                    }),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }