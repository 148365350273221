<template>
    <v-card class="mt-5">
        <v-card-title>{{ $t('console.platforms.mturk.hits.create.hit.title') }}</v-card-title>

        <v-form v-model="valid">
            <v-simple-table dense class="mt-4">
                <tbody>
                    <tr>
                        <td width="300px">
                            {{ $t('console.platforms.mturk.hits.create.hit.numHITs') }}
                            <required-badge></required-badge>
                        </td>
                        <td>
                            <validated-text-field
                                filled
                                :min="1"
                                :step="1"
                                type="number"
                                style="width:200px;"
                                v-model.number="inputs.numCreateHITs"
                                :rules="[rules.positiveInteger]"
                            />
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>

            <v-card-subtitle>
                <b>{{ $t('console.platforms.mturk.hits.create.hit.parameters') }}</b>
                <help-button name="CreateHITWithHITType" />
            </v-card-subtitle>

            <v-simple-table dense>
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td width="300">
                                {{ $t('console.platforms.mturk.hits.create.hit.table.maxAsgmt.text') }}
                                <!--MaxAssignments <required-badge />-->
                                <v-tooltip right max-width="300">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-on="on" v-bind="attrs" small v-if="!isMaxAssignmentsEditable" @click="isMaxAssignmentsEditable = true">mdi-pencil</v-icon>
                                    </template>
                                    <span>
                                        <v-icon small dark>mdi-alert</v-icon>
                                        {{ $t('console.platforms.mturk.hits.create.hit.table.maxAsgmt.tooltip') }}
                                    </span>
                                </v-tooltip>
                            </td>
                            <td>
                                <validated-text-field
                                    :disabled="!isMaxAssignmentsEditable"
                                    type="number"
                                    min=1
                                    step=1
                                    style="width:200px;"
                                    v-model.number="inputs.hitParams.MaxAssignments"
                                    :rules="[rules.positiveInteger]"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('console.platforms.mturk.hits.create.hit.table.lifeTime') }}<required-badge /></td>
                            <td>
                                <validated-text-field
                                    type="number"
                                    min=0
                                    step=10
                                    style="width:200px;"
                                    v-model.number="inputs.hitParams.LifetimeInSeconds"
                                    :rules="[rules.positiveInteger]"
                                />
                                {{ secondsToFormattedTime(inputs.hitParams.LifetimeInSeconds, ['weeks', 'days', 'hrs', 'mins', 'secs']) }}
                            </td>
                        </tr>
                        <tr>
                            <td>{{ $t('console.platforms.mturk.hits.create.hit.table.reqAnnot') }}<required-badge /></td>
                            <td>
                                <validated-text-field
                                    v-model="inputs.hitParams.RequesterAnnotation"
                                    :rules="[() => (true)]"
                                />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-form>
    </v-card>
</template>

<script>
import HelpButton from '../../ui/HelpButton'
import ValidatedTextField from './ui/ValidatedTextField'
import RequiredBadge from './ui/RequiredBadge'
import { defaultHITParams, defaultNumCreateHITs } from './create-hit-manifest'
import { secondsToFormattedTime } from '@/lib/utils'
import rules from '@/lib/input-rules'

export default {
    components: {
        HelpButton,
        ValidatedTextField,
        RequiredBadge
    },
    data: () => ({
        rules,

        valid: false,
        isMaxAssignmentsEditable: false,
        inputs: {
            hitParams: defaultHITParams,
            numCreateHITs: defaultNumCreateHITs,
        },
    }),
    methods: {
        secondsToFormattedTime
    },
    watch: {
        inputs: {
            deep: true,
            handler() { this.$emit('update', this.inputs); }
        },
        valid() {
            this.$emit('validate', this.valid);
        }
    },
    mounted() {
        this.$emit('update', this.inputs);
    }
}
</script>
