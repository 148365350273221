var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("page-toolbar", {
            attrs: {
              "previous-page-label": _vm.$t(
                "console.platforms.mturk.hits.create.toolbar.prevPageLabel"
              ),
              title: _vm.$t(
                "console.platforms.mturk.hits.create.toolbar.title"
              ),
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("hit-type-form-card", {
            attrs: { client: _vm.client },
            on: {
              update: _vm.updateHITTypeParams,
              validate: (_valid) => {
                _vm.valid.HITType = _valid
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "10" } },
        [
          _c("hit-form-card", {
            attrs: { client: _vm.client },
            on: {
              update: _vm.updateHITParams,
              validate: (_valid) => {
                _vm.valid.HIT = _valid
              },
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "text-right", attrs: { cols: "10" } },
        [
          _c("post-hits-button", {
            attrs: {
              client: _vm.client,
              credentials: _vm.credentials,
              hitTypeInputs: _vm.hitTypeInputs,
              hitInputs: _vm.hitInputs,
              disabled: !(_vm.valid.HITType && _vm.valid.HIT),
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }